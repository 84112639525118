import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';

const BasicDatatable = () => {
  const { t } = useTranslation();
  const { CurrencySymbol } = GlobalSet();
  const history = useHistory();
  const user_type = localStorage.getItem('user_type');
  if(user_type != "Sub-Agent"){
   history.push('/dashboard');
   swal("Warning","Permission Not Granted !","warning");
  }
 /*.....income data......*/
    const [getagent, setAgent] = useState([]);
    const fetchData = () => {
      const selectedCurrency = localStorage.getItem('selectedCurrency');
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/ag-usre-list',data)
         .then(response=>{
           setAgent(response.data.data)
           console.log(response.data.data)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }
  };
  useEffect(() => {
    fetchData();
  }, []);




  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Users List</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      SL
                    </th>
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      Name
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Username
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Type
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Email
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Balance
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Create Time
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                      {getagent.map((agentrow, index) => (
                        <tr key={agentrow.id} className="odd" role="row">
                          <td className="sorting_1">{index + 1}</td>
                          <td className="sorting_1">{agentrow.name}</td>
                          <td className="sorting_1">{agentrow.username} </td>
                          <td className="sorting_1">{agentrow.user_type} </td>
                          <td className="sorting_1">{agentrow.email} </td>
                          <td className="sorting_1">{ CurrencySymbol }{ parseFloat(parseFloat(agentrow.balance).toFixed(4))} </td>
                          <td className="sorting_1">{agentrow.created_at}</td>
                          <td className="sorting_1">
                              <Link key={agentrow.id} to={"ag-view-user/"+agentrow.username}>
                                <i style={{ fontSize: '20px' }} class=" fa fa-info-circle " aria-hidden="true"></i>
                             </Link>                              
                          </td> 

                        </tr>
                      ))}
                </tbody>
               
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
